@import 'assets/styles/_breakpoints.scss';
@import 'assets/styles/_containers.scss';

.root {
  position: fixed;
  background: $color-white;
  top: 0;
  z-index: 2;
  width: 100%;
}

.nav-container {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  @include container;

  .navbar {
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    background: $color-white;
    height: $navbar-height;
    width: 100%;
  }
}

.nav-logo-wrap {
  display: block;
  margin-top: auto;
  margin-bottom: auto;
  max-width: 16rem;
  margin-right: 1rem;
  padding-left: 0.9375rem;

  @include breakpoint(sm, min) {
    margin-left: 0;
  }

  .nav-logo {
    height: auto;
    vertical-align: middle;
    border-style: none;
    z-index: 9;
  }
}

.nav-menu-wrap {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  justify-content: center;

  @include breakpoint(lg, max) {
    margin-left: auto;
  }

  .nav-menu {
    display: flex;
    list-style: none;
    padding-left: 0;

    .nav-links-wrap {
      display: none;
      list-style: none;
      padding-left: 0;

      @include breakpoint(xxl, min) {
        display: flex;
      }

      .nav-link {
        cursor: pointer;
        font-style: normal;
        font-weight: bold;
        text-decoration: none;
        color: $color-dark-blue;
        display: flex;
        align-items: center;
        font-size: 1rem;
        line-height: 1.4375;
        margin-right: 0.88rem;
        letter-spacing: 0.02em;
        position: relative;
        padding-top: 1.8125rem;
        padding-bottom: 1.8125rem;
        box-sizing: border-box;
        position: relative;

        &.active::after {
          position: absolute;
          width: 100%;
          bottom: 0.625rem;
          content: '';
          border-bottom: 0.125rem solid $color-aqua;
        }
      }
    }
    .nav-buttons-wrap {
      display: flex;
      gap: 0.88rem;
    }
  }
}

.link-has-sub {
  &:hover {
    .sub-menu-wrap {
      display: block;

      .sub-nav-link {
        display: block;
      }
    }
  }
}

.sub-menu-wrap {
  display: none;
  width: 200px;
  position: absolute;
  background: white;
  top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  left: 100%;
  transform: translateX(-33%);

  &:hover {
    display: block;

    .sub-nav-link {
      display: block;
    }
  }

  .sub-nav-link {
    display: none;
    padding-bottom: 0.625rem;
    padding-top: 0.625rem;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    box-sizing: border-box;
    cursor: pointer;
    text-decoration: none;
    color: $color-dark-blue;
    transition: transform 0.25s ease-in-out;

    &:hover {
      transform: translateX(0.3125rem);
    }
  }
}

.search-wrap-desktop {
  width: 350px;
  display: flex;
}
