@import 'assets/styles/_breakpoints.scss';

.root {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  border: 2px solid $color-aqua;
  margin-top: auto;
  margin-bottom: auto;
  padding: 0.3125rem 0.625rem;
  border-radius: 2rem;
  width: 100%;
  max-width: 25rem;
  margin-right: 0.625rem;
}

.label {
  display: none;
}

.button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.input {
  border: none;
  outline: none;
  font-size: 1rem;
  margin-left: 1rem;
  line-height: 1;
  height: 1.25rem;
  width: 100%;
}
