@import 'assets/styles/_breakpoints.scss';

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 20px 30px;
}

.title {
  @include h3;
  margin: 0;
  color: $color-header-blue;
}

.close {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0.1s ease-in-out opacity;

  &:hover {
    opacity: 0.5;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 0 30px;
}

.empty-cart {
  @include h3;
  text-align: center;
}

.cart-items {
  list-style: none;
  padding: 0;
  margin: 0;
}

.totals {
  list-style: none;
  padding: 0;
  margin: 0;
}

.subtotal,
.tax,
.shipping,
.total {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 0.3125rem;

  span:first-child {
    font-size: 1.25rem;
    color: $color-header-blue;
  }

  span:last-child {
    font-size: 1.125rem;
    color: $color-dark-grey;
  }
}

.total {
  margin-top: 1.875rem;
}

.checkout {
  display: block;
  font-family: $font-family-futura;
  font-weight: $font-weight-bold;
  font-size: 0.8125rem;
  line-height: 1.3;
  color: $color-header-blue;
  border-radius: 0.3125rem;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  width: 100%;
  margin-top: 1.875rem;
  box-sizing: border-box;
  background: $color-yellow;
  text-decoration: none;
  @include noSelect;
  margin-bottom: 1.875rem;
}

.out-of-stock {
  margin-top: 1.875rem;
  color: $color-red;
  @include paragraph;
}
