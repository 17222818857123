@import 'assets/styles/_breakpoints.scss';
@import 'assets/styles/_containers.scss';

.root {
  width: 100%;
}

.viewport {
  overflow: hidden;
}

.slider {
  display: flex;
  width: 100%;
}

.slide {
  position: relative;
}

.col-1 {
  flex: 0 0 100%;
}

.col-2 {
  flex: 0 0 50%;
}

.col-3 {
  /**
    This is not calc(100% / 3) as we need the combined total to render at least
    100% for embla to allow looping, we just need slightly over that see:
    https://github.com/davidcetinkaya/embla-carousel/issues/149
  */
  flex: calc(100.1% / 3);
}

.col-4 {
  flex: 0 0 25%;
}

.nav {
  margin-top: 2.875rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 0.625rem;

  @include breakpoint(md, min) {
    font-size: 1.25rem;
  }
}

.dot {
  border: none;
  outline: none;
  width: 1em;
  height: 1em;
  background-color: $color-white;
  border-radius: 50%;
  padding: 0;
  margin-right: 0.75em;
  cursor: pointer;

  &.selected {
    background-color: $color-header-blue;
  }
}
