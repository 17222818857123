@import 'assets/styles/_breakpoints.scss';
@import 'assets/styles/_containers.scss';

.root {
  position: fixed;
  bottom: 1rem;
  left: 1rem;

  .scroll-to-top-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    cursor: pointer;
    background: $color-yellow;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    transform: rotate(180deg);
  }

  svg {
    height: 0.625rem;
    width: 3rem;
  }
}
