@import 'assets/styles/_variables.scss';

.root {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.input {
  border-radius: 0.3125;
  background-color: $color-input-bg;
  border: 1px solid $color-input-border;
  outline: none;
  font-size: 1.125rem;
  box-sizing: border-box;
  padding: 0.625rem;
  height: 2.625rem;
  /* Fixes FF Input Flexbox issues */
  min-width: 0;
  max-width: 3.125rem;
}

.action {
  border-radius: 0.3125;
  background-color: $color-input-bg;
  border: 1px solid $color-input-border;
  outline: none;
  padding: 0.625rem;
  display: block;
  cursor: pointer;
  height: 2.625rem;
  width: 2.625rem;
}

.inactive {
  @include noSelect;
  pointer-events: none;
  opacity: 0.5;
}
