@import 'assets/styles/_breakpoints.scss';

.root {
  background-color: $color-white;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  box-shadow: 1px 4px 10px 1px rgba(0, 0, 0, 0.18);
  padding: 1.25rem;
  margin-bottom: 1.5625rem;
}

.image-wrapper {
  position: relative;
  width: calc(100% / 4);
  margin-right: 1.25rem;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.info-1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 75%;
}

.name-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.3125rem;
}

.name {
  font-size: 1.25rem;
  color: $color-header-blue;
  text-decoration: none;
  text-transform: uppercase;
}

.remove {
  margin-left: auto;
  background: none;
  border: none;
  outline: none;
  color: $color-header-blue;
  cursor: pointer;
  padding-right: 0;
}

.price {
  margin-bottom: 3.4375rem;
  font-size: 1.125rem;
  color: $color-dark-grey;
  margin-top: auto;
  margin-bottom: 1.5625rem;

  @include breakpoint(sm, min) {
    margin-bottom: 0.5rem;
  }
}

.qty-price-wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  margin-top: 0.9375rem;
  @include breakpoint(sm, min) {
    flex-direction: row-reverse;
    margin-top: 1.875rem;
  }
}

.out-of-stock {
  color: $color-red;
  @include paragraph;
  margin-top: 0.3125rem;
}

.options {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.option-name {
  margin-right: 0.3125rem;
}

.option-value {
  margin-right: 0.625rem;
  font-weight: $font-weight-bold;
}
