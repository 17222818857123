@import 'assets/styles/_breakpoints.scss';
@import 'assets/styles/_containers.scss';

.mobile-menu {
  position: fixed;
  background: white;
  transition: opacity 0.2s ease-in-out;
  left: 50%;
  top: $navbar-height; /** navbar height **/
  height: 0;
  opacity: 0;
  transform: translateX(-50%);
  width: 100%;
  pointer-events: none;
  overflow-y: auto;
  padding-top: 0.625rem;

  @include breakpoint(xxl, min) {
    display: none;
  }

  .links-wrap {
    @include container;
    display: flex;
    flex-direction: column;
    height: 0;
    padding-left: 0.9375rem;
    transition: height 0.5s ease-in-out;
    background: white;
    border-top: 0.0625rem solid $color-light-grey;
    margin-top: 1.25rem;

    .mobile-nav-link {
      padding-top: 1rem;
      padding-bottom: 1rem;
      font-style: normal;
      font-weight: bold;
      text-decoration: none;
      color: $color-dark-blue;
      display: flex;
      align-items: center;
      font-size: 1.4375rem;
      line-height: 1.4375rem;
      margin-right: 1.875rem;
      letter-spacing: 0.02em;
      position: relative;
      cursor: pointer;

      .mobile-nav-link-icon {
        position: absolute;
        right: 1.25rem;
        transform: rotate(180deg);

        &.rotate-arrow {
          transform: rotate(360deg);
        }
      }
    }
  }
}

.mobile-menu-open {
  height: 100vh;
  opacity: 100%;
  pointer-events: initial;

  .links-wrap {
    height: 100vh;
  }
}

.burger {
  display: block;
  cursor: pointer;
  box-sizing: border-box;
  padding-right: 1.875rem;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
  padding-left: 1.25rem;

  @include breakpoint(xxl, min) {
    display: none;
  }

  &:focus {
    outline: none;
  }

  div {
    position: relative;
    background: $color-dark-blue;
    transition: all 0.5s linear;
    width: 1.875rem;
    height: 0.3125rem;
    border-radius: 0.625rem;
    transform-origin: 0.0625rem;
    margin-bottom: 0.3125rem;
    z-index: 9;
  }
}

.searchbar-wrap {
  display: flex;
  justify-content: center;
  @include container;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  box-sizing: border-box;

  @include breakpoint(sm, min) {
    padding-left: 0;
  }

  .searchbar {
    margin-right: auto;
  }
}

.mobile-nav-link-wrap {
  border-bottom: 0.0625rem solid $color-light-grey;
  cursor: pointer;
}

.sub-nav-link {
  visibility: 100%;
  padding-bottom: 0.625rem;
  padding-top: 0.625rem;
  padding-left: 0.9375rem;
  box-sizing: border-box;
  cursor: pointer;
  text-decoration: none;
  color: $color-dark-blue;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  transition: all 0.25s ease-in-out;

  &:hover {
    padding-left: 1.25rem;
  }
}

.hidden {
  display: none;
}

.burger-close {
  transform: all 0.25s ease-in-out;
  :first-child {
    transform: rotate(0);
  }

  :nth-child(2) {
    opacity: 1;
    transform: translateX(0);
  }

  :last-child {
    transform: rotate(0);
  }
}

.burger-open {
  transform: all 0.25s ease-in-out;

  :first-child {
    transform: rotate(45deg);
  }

  :nth-child(2) {
    opacity: 0;
    transform: translateX(1.25rem);
  }

  :last-child {
    transform: rotate(-45deg);
  }
}
