@import 'assets/styles/_breakpoints.scss';

.root {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
  height: 100%;
}

.backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $color-backdrop;
  backdrop-filter: blur(0.8px);
  height: 100%;
}

.sidebar-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding-left: 2.5rem;
  height: 100%;
}

.sidebar {
  background-color: $color-sidebar;
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;

  @include breakpoint(md, min) {
    max-width: 480px;
  }
}

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 20px 30px;
}

.title {
  @include h3;
  margin: 0;
  color: $color-header-blue;
}

.close {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0.1s ease-in-out opacity;

  &:hover {
    opacity: 0.5;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 0 30px;
}

.empty-cart {
  @include h3;
  text-align: center;
}

.cartitems {
  list-style: none;
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  box-shadow: 1px 4px 10px 1px rgba(0, 0, 0, 0.18);
  padding: 1.25rem;
  margin-bottom: 1.5625rem;
  font-size: 1.125rem;
  li {
    display: flex;
    gap: 0.5rem;
  }
}

.remove {
  margin-left: auto;
  background: none;
  border: none;
  outline: none;
  color: #1a61a8;
  cursor: pointer;
  padding-right: 0;
}

.contactform {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  label {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
  .input {
    display: block;
    border: 1px solid #696969;
    border-radius: 0.3125rem;
    padding: 0.75rem;
    color: #696969;
    font-size: 1rem;
  }
}

.checkout,
.addmore {
  display: block;
  font-family: $font-family-futura;
  font-weight: $font-weight-bold;
  font-size: 0.8125rem;
  line-height: 1.3;
  color: $color-header-blue;
  border: none;
  border-radius: 0.3125rem;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  width: 100%;
  box-sizing: border-box;
  background: $color-yellow;
  text-decoration: none;
  @include noSelect;
  margin-bottom: 1.875rem;
}

.addmore {
  color: #fff;
  background: $color-header-blue;
}

.noitems {
  margin-top: 1.875rem;
  font-size: 1.125rem;
}
