@use 'sass:math';
@import 'assets/styles/_breakpoints.scss';

.root {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: 100%;
}

.wrap {
  flex-wrap: wrap;
}

.nowrap {
  flex-wrap: nowrap;
  align-items: stretch;
}

.reverse {
  flex-wrap: wrap-reverse;
}

@each $breakpoint, $value in $breakpoints {
  @for $i from 1 through $grid-columns {
    @if $breakpoint == xs {
      .col-#{$breakpoint}-#{$i} {
        box-sizing: border-box;
        position: relative;
        flex-basis: calc(100% / ($grid-columns / $i));
        max-width: calc(100% / ($grid-columns / $i));

        /**
          Embla needs min-width instead of flex-basis to function.

          Also, in order for Embla to allow looping, the column width for each
          slide set needs to equal at least 100%, not even a hair under.
          For example, calc(100% / 3) is just slightly under and would not
          allow looping. So, we calc based on 100.1% instead of 100% for embla
          columns. See: https://github.com/davidcetinkaya/embla-carousel/issues/149
        */
        &.embla-col {
          min-width: calc(100.1% / ($grid-columns / $i));
          min-height: none;
          @include breakpoint($breakpoint, 'min') {
            min-height: none;
          }
        }
      }
    } @else {
      @include breakpoint($breakpoint, 'min') {
        .col-#{$breakpoint}-#{$i} {
          box-sizing: border-box;
          position: relative;
          flex-basis: calc(100% / ($grid-columns / $i));

          &.embla-col {
            min-width: calc(100.1% / ($grid-columns / $i));
          }
        }
      }
    }
  }
}

@each $gutter-size in $gutter-sizes {
  @each $breakpoint, $value in $breakpoints {
    @if $breakpoint == xs {
      .gutter-#{$breakpoint}-#{$gutter-size} {
        margin-left: -#{$gutter-size} + px;
        width: calc(100% + #{$gutter-size}px);
        padding-top: (math.div($gutter-size, 2)) + px;
        padding-bottom: (math.div($gutter-size, 2)) + px;

        > [class*='col-'] {
          padding-top: (math.div($gutter-size, 2)) + px;
          padding-bottom: (math.div($gutter-size, 2)) + px;
          padding-left: ($gutter-size) + px;
        }
      }
    } @else {
      @include breakpoint($breakpoint, 'min') {
        .gutter-#{$breakpoint}-#{$gutter-size} {
          margin-left: -#{$gutter-size} + px;
          width: calc(100% + #{$gutter-size}px);
          padding-top: (math.div($gutter-size, 2)) + px;
          padding-bottom: (math.div($gutter-size, 2)) + px;

          > [class*='col-'] {
            padding-top: (math.div($gutter-size, 2)) + px;
            padding-bottom: (math.div($gutter-size, 2)) + px;
            padding-left: ($gutter-size) + px;
          }
        }
      }
    }
  }
}

@for $i from 1 through $grid-columns {
  @each $breakpoint, $value in $breakpoints {
    @if $breakpoint == xs {
      .order-#{$breakpoint}-#{$i} {
        order: $i;
      }
    } @else {
      @include breakpoint($breakpoint, 'min') {
        .order-#{$breakpoint}-#{$i} {
          order: $i;
        }
      }
    }
  }
}
