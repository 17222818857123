@import 'assets/styles/_containers.scss';

.root {
  background-color: $color-content-bg;
}

/*
  We don't want the Layout to have a margin top when displaying the static_footer
  or any other pages w/o the sticky header.
*/
header + .root {
  margin-top: $navbar-height;
}
