@import 'assets/styles/_variables.scss';

.btn {
  display: block;
  font-family: $font-family-futura;
  font-weight: $font-weight-bold;
  font-size: 0.8125rem;
  line-height: 1.3;
  border-radius: 0.3125rem;
  border: none;
  padding: 0.875rem 2rem;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  width: 100%;
  box-sizing: border-box;
  color: $color-header-blue;
  text-decoration: none;
  margin-bottom: 0.625rem;
  @include noSelect;
  background: $color-yellow;
}
