@import 'assets/styles/_variables.scss';
@import 'assets/styles/_breakpoints.scss';

.btn-wrap {
  padding: 0.875rem;

  border-radius: 0.3125rem;
  background: $color-yellow;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  @include noSelect;
  z-index: 9;
  margin-left: auto;

  @include breakpoint(sm, min) {
    padding: 0.875rem 0.875rem;
  }

  .btn {
    color: $color-dark-blue;
    font-weight: $font-weight-bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0;

    @include breakpoint(md, min) {
      font-size: 1rem;
    }

    .btn-text {
      margin-left: 0.5625rem;
    }

    .cart-count-wrapper {
      position: relative;
    }

    .cart-count {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%, -30%);
      background-color: $color-white;
      width: 1.5em;
      height: 1.5em;
      line-height: 1rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.75rem;
    }

    .cart-count-above-hundred {
      padding-left: 0.125rem;
      padding-right: 0.125rem;
    }
  }
}
