@import 'assets/styles/_breakpoints.scss';

.root {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
  height: 100%;
}

.backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $color-backdrop;
  backdrop-filter: blur(0.8px);
  height: 100%;
}

.sidebar-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding-left: 2.5rem;
  height: 100%;
}

.sidebar {
  background-color: $color-sidebar;
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;

  @include breakpoint(md, min) {
    max-width: 480px;
  }
}
