@import './_breakpoints.scss';

@mixin container {
  width: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;

  @each $breakpoint, $container-max-width in $container-max-widths {
    @include breakpoint($breakpoint) {
      max-width: $container-max-width;
    }
  }
}
