@import 'assets/styles/_breakpoints.scss';
@import 'assets/styles/_containers.scss';

.root {
  background-color: $color-white;
}

.top-footer {
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;
  width: 100%;
  @include container;

  @include breakpoint(sm, max) {
    flex-direction: column;
  }

  .logo-wrapper {
    box-sizing: border-box;
    height: 6.25rem;
    width: 100%;
    position: relative;
  }

  .logo-link {
    display: block;
    width: 100%;
  }

  .img-col {
    align-items: center;
    padding-bottom: 0.625rem;
    box-sizing: border-box;

    @include breakpoint(md, min) {
      padding-bottom: 0;
    }
  }

  .col {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    word-break: break-all;

    @include breakpoint(sm, max) {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      padding-left: 1.25rem;
    }

    .email,
    .phone,
    .address {
      display: flex;
      align-items: center;
      margin-bottom: 1.25rem;
    }

    .email {
      strong, em {
        display: block;
      }
    }

    .roc {
      display: flex;
      align-items: center;
      font-weight: $font-weight-bold;
      margin-bottom: 0.3125rem;
    }

    .roc-numbers {
      padding-left: 1.625rem;
    }

    .roc,
    .roc-numbers {
      color: $color-link;
    }

    .email-link,
    .phone-link,
    .address-link {
      text-decoration: none;
      font-size: 1rem;
      color: $color-link;
    }

    .icon-wrapper {
      color: $color-aqua;
      margin-right: 0.625rem;
    }

    &.social-col {
      align-items: center;
      justify-content: center;
      flex-direction: row;
      padding-bottom: 0.625rem;
      height: 100%;
      .icon-wrapper {
        color: $color-link;
        margin: 0 0.6rem;
        &:hover {
          color: $color-darker-blue;
        }
      }
      @include breakpoint(md, max) {
        padding: 0.625rem 0 1.625rem;
      }
      @include breakpoint(sm, max) {
        padding: 0.625rem 0 1.625rem 1.25rem;
      }
    }
  }
  .disclaimer {
    font-size: 12px;
    text-align: center;
    @include breakpoint(md, max) {
      padding: 2rem 0 0.5rem;
    }
  }
}

.shopperapproved {
  padding-top: 15px;
}
.shopperapproved .shopperlink img {
  border-radius: 4px;
}

.bot-footer {
  background: $color-dark-blue;

  .nav {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    height: $navbar-height + 3rem;
    font-size: 0.875rem;
    @include container;

    @include breakpoint(xl, min) {
      flex-direction: row;
      box-sizing: border-box;
      align-items: space-around;
    }

    @include breakpoint(md, max) {
      flex-direction: column;
      height: $navbar-height + 5rem;
    }

    .nav-link {
      color: $color-white;
      text-decoration: none;
      margin-left: 1.5625rem;
      margin-right: 1.5625rem;
      margin-bottom: 0.625rem;

      @include breakpoint(md, min) {
        margin-bottom: 0;
      }
    }
  }
}
