@import 'assets/styles/_breakpoints.scss';

.breadcrumb {
  line-height: 1.42;
  margin-bottom: 0.625rem;
}

.breadcrumb-item {
  font-size: 1rem;
  text-decoration: none;
  color: $color-header-blue;
  text-transform: uppercase;

  &:after {
    content: '>';
    margin-left: 0.25em;
    margin-right: 0.25em;
  }

  &:last-child {
    &:after {
      content: '';
    }
  }
}
