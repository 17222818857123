@import 'assets/styles/_breakpoints.scss';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  @include breakpoint(lg, min) {
    padding-top: 0;
  }
}

.content-wrapper {
  z-index: 1;
}

.title {
  font-size: 1.5rem;
  line-height: 1.43;
  margin: 0 auto;
  color: $color-white;
  max-width: 250px;
  text-align: center;
  padding: 0 0.9375rem;
  @include noSelect;

  @include breakpoint(md, min) {
    font-size: 2.5rem;
    max-width: none;
  }
}

.subtitle {
  font-size: 1.5rem;
  line-height: 1.43;
  margin: 0 auto;
  color: $color-white;
  max-width: 250px;
  text-align: center;
  padding: 0 0.9375rem;
  @include noSelect;

  @include breakpoint(md, min) {
    font-size: 2rem;
    max-width: none;
  }
}

.button {
  display: block;
  font-family: $font-family-futura;
  font-weight: $font-weight-bold;
  font-size: 0.8125rem;
  line-height: 1.3;
  color: $color-white;
  border-radius: 0.3125rem;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  margin-top: 1.875rem;
  box-sizing: border-box;
  background: $color-header-blue;
  text-decoration: none;
  @include noSelect;
  margin-bottom: 1.5625rem;
  padding: 0.75rem 5rem;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;

  @include breakpoint(lg, min) {
    max-width: 275px;
    padding: 0.75rem 6rem;
  }
}

.bg-wrapper {
  z-index: 0;
  span {
    position: relative!important;
    top: auto!important;
    left: auto!important;
    bottom: auto!important;
    right: auto!important;
    img {
      position: relative!important;
      top: auto!important;
      left: auto!important;
      bottom: auto!important;
      right: auto!important;
      width: 100%!important;
      height: auto!important;
    }
  }

  img {
    display: block;
    @include breakpoint(lg, max) {
      display: none!important;
    }
    &.bg-mobile {
      display: none!important;
      @include breakpoint(lg, max) {
        display: block!important;
      }
    }
  }
}

.content-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 1rem 0 0;
  @include breakpoint(sm, min) {
    padding: 2rem 0 0;
  }
  @include breakpoint(md, min) {
    padding: 5rem 0 0;
  }
}


.bg-overlay-wrapper {
  width: calc(100% + 20px);
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: -1px;
  margin-top: auto;
  overflow: hidden;
  z-index: 1;
}
