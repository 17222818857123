@import 'assets/styles/_variables.scss';

.root {
  position: relative;
}

.control {
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid $color-input-border;
  background-color: $color-input-bg;
  padding: 0.9375rem;
  border-radius: 0.3125rem;
  box-shadow: 1px 4px 10px 1px rgba(0, 0, 0, 0.18);
  @include noSelect;
}

.dropdown {
  display: none;
  width: 100%;
  position: absolute;
  box-sizing: border-box;
  top: calc(100% + 0.3125rem);
  list-style: none;
  padding: 0.3125rem 0;
  border-radius: 0.3125rem;
  margin: 0;
  border: 1px solid $color-input-border;
  background-color: $color-input-bg;
  box-shadow: 1px 4px 10px 1px rgba(0, 0, 0, 0.18);
  z-index: 1;

  &.open {
    display: block;
  }
}

.dropdown-item {
  box-sizing: border-box;
  cursor: pointer;
  padding: 0.3125rem 0.9375rem;
  @include noSelect;

  &:hover {
    background-color: $color-light-grey;
  }

  &.selected {
    background-color: $color-dark-grey;
    color: $color-white;
  }
}

.arrow {
  position: absolute;
  right: 0.9375rem;
  top: 50%;
  transform: translateY(-50%);

  &.open {
    transform: rotate(180deg) translateY(50%);
  }
}
