// Colors
// Pulled from: https://shastapools.com/wp-content/themes/shastapools/assets/styles/scss/globals/_variables.scss
$color-white: #ffffff;
$color-dark-blue: #0b6db5;
$color-darker-blue: #163f5d;
$color-mid-blue: #67abe1;
$color-light-blue: #8dd7f7;
$color-yellow-button-blue: #31578c;
$color-gallery-blue: #074c7d;
$color-header-blue: #1a61a8;
$color-product-slider-blue: #9dd6f4;
$color-aqua: #67d2c4;
$color-yellow: #ffcf62;
$color-mint: #2cd5c4;
$color-content: #696969;
$color-light-grey: #f4f4f4;
$color-content-bg: $color-light-grey;
$color-dark-grey: #5e5e5e;
$color-link: #2d6db0;
$color-backdrop: rgba(0, 0, 0, 0.4);
$color-sidebar: $color-light-grey;
$color-input-bg: $color-white;
$color-input-border: #bebebe;
$color-red: #e2786e;

// Typography
$font-family-brandon: brandon-grotesque, sans-serif;
$font-family-futura: Futura, sans-serif;
$font-weight-normal: 400;
$font-weight-bold: 700;

@mixin h1 {
  font-weight: $font-weight-bold;
  font-size: 1.75rem;
  line-height: 1.42;
  letter-spacing: 0.02em;
  word-break: break-word;

  @include breakpoint(md, min) {
    font-size: 2.625rem;
  }
}

@mixin h2 {
  font-size: 1.25rem;
  line-height: 1.42;

  @include breakpoint(md, min) {
    font-size: 1.75rem;
  }
}

@mixin h3 {
  font-size: 1.25rem;
  line-height: 1.3;
}

@mixin paragraph {
  font-size: 1rem;
  line-height: 1.42;
}

@mixin noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Old version of Firefox */
  -ms-user-select: none; /* Internet Explorer or Edge */
  user-select: none; /* All modern browsers */
}

$navbar-height: 5rem;

// Breakpoints
$breakpoints: (
  xs: 480px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

$container-max-widths: (
  xs: 460px,
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
);

// Grid
$grid-columns: 12;
$gutter-sizes: 5, 10, 15, 20, 25, 30, 35, 40;
